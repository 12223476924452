import React, { FC, FormEvent, useState } from "react";
import { EmailValidation } from "./EmailValidation";

export const Home: FC<{}> = () => {
  const [username, setUsername] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const formHandler = (event: FormEvent) => {
    event.preventDefault();
    setHasSubmitted(true);
  };
  return (
    <div className="container">
      <h1 className="text-center mt-3">Create/Reset Student Password</h1>
      <div className="text-center alert alert-info">
        Employees please submit a{" "}
        <a
          href="https://snow.teamdynamix.com/TDClient/283/Portal/Requests/TicketRequests/NewForm?ID=7uGyLLUtGsk_&RequestorType=Service"
          className="fw-bold"
        >
          Help Desk Ticket
        </a>{" "}
        or contact the IT Office: 435-283-7099
      </div>
      <div className="row justify-content-center">
        <div className="col-auto">
          <form onSubmit={formHandler}>
            <label htmlFor="username" className="form-label mx-5">
              To reset your password, enter your Snow College email address. Your username is your Snow College email 
              address provided to you in your admit email or letter:
            </label>
            <div className="text-muted text-center">
              Example: John.Stevens9@students.snow.edu
            </div>
            <input
              type="text"
              id="username"
              name="username"
              placeholder="Your Snow College Email Address"
              className="form-control mt-1"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              disabled={hasSubmitted}
            />
            {hasSubmitted ? (
              <button
                className="btn btn-outline-bold w-100 my-3"
                onClick={(e) => {
                  e.preventDefault();
                  setUsername("");
                  setHasSubmitted(false);
                }}
              >
                Try Different Email Address
              </button>
            ) : (
              <button className="btn btn-bold w-100 my-3">Submit</button>
            )}
          </form>
        </div>
        <div className="text-center">
          <div className="lead">
            Please contact the Helpdesk or IT Office with any issues:
          </div>
          <div className="lead">435-283-7777 or 435-283-7099</div>
        </div>
      </div>
      {hasSubmitted && <EmailValidation username={username} />}
    </div>
  );
};
