import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "bootstrap";
import "./assets/custom.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import App from "./App";
import { QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { getQueryClient } from "./services/queryClient";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = getQueryClient();

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </StrictMode>
);
