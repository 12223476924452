import { Toaster } from "react-hot-toast";
import { Route, Routes } from "react-router-dom";
import { NavBar } from "./components/NavBar";
import { Home } from "./pages/home/Home";
import { IncomingRequestWithUsername } from "./pages/home/IncomingRequestWithUsername";
import { PasswordResetWrapper } from "./pages/passwordReset/PasswordResetWrapper";
import { UsernameLookup } from "./pages/usernameLookup/UsernameLookup";
import { EmailSent } from "./pages/usernameLookup/EmailSent";

function App() {
  return (
    <>
      <NavBar />
      <Toaster />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/resetPassword" element={<PasswordResetWrapper />} />
        <Route path="/reset" element={<IncomingRequestWithUsername />} />
        <Route path="/username" element={<UsernameLookup />} />
        <Route path="/usernameEmail" element={<EmailSent />} />
      </Routes>
    </>
  );
}

export default App;
