import React, { FC, FormEvent, useState } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "../../components/Spinner";
import { useResetPasswordMutation, useTokenStatusQuery } from "./apiHooks";

export const PasswordReset: FC<{ token: string }> = ({ token }) => {
  const resetPasswordMutation = useResetPasswordMutation();
  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [hasTouched, setHasTouched] = useState(false);
  const [passwordUpdated, setPasswordUpdated] = useState(false);

  const tokenStatusQuery = useTokenStatusQuery(token);

  const isLongEnough = password.length >= 15;
  const hasUppercaseLetters = /.*[A-Z].*/.test(password);
  const hasLowercaseLetters = /.*[a-z].*/.test(password);
  const hasNumbers = /.*[0-9].*/.test(password);
  const hasSymbols = /.*[!@#$%^&*()_+\-=:;{[}\]<,>.?/~`'"].*/.test(password);
  const passwordsMatch = password === confirmationPassword;

  const threeOfFour =
    [hasUppercaseLetters, hasLowercaseLetters, hasNumbers, hasSymbols].filter(
      Boolean
    ).length >= 3;
  const meetsRequirements = isLongEnough && passwordsMatch && threeOfFour;

  const showIcon = (fulfillsRule: boolean) => {
    if (!hasTouched) return "";
    if (fulfillsRule) return " ✔️";
    else return " ❌";
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    resetPasswordMutation
      .mutateAsync({ password, token: token ?? "" })
      .then(() => setPasswordUpdated(true));
  };

  if (tokenStatusQuery.isLoading) return <Spinner />;
  if (tokenStatusQuery.isError)
    return <div className="text-danger m-3 text-center">Bad token</div>;
  if (!tokenStatusQuery.data) return <div>no data for token</div>;

  if (tokenStatusQuery.data.expired)
    return (
      <div className="text-center fw-bold text-danger">
        Expired Request, please click <Link to="/">here</Link> to make an new
        password reset request
      </div>
    );
  if (tokenStatusQuery.data.used)
    return <div className="text-center">Reset token has already been used</div>;

  const sumOfTrues =
    (hasUppercaseLetters ? 1 : 0) +
    (hasLowercaseLetters ? 1 : 0) +
    (hasNumbers ? 1 : 0) +
    (hasSymbols ? 1 : 0);
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="row justify-content-center">
            <div className="col-auto">
              <div>Rules:</div>
              <ul>
                <li>
                  Cannot Contain Your Name
                </li>
                <li>
                  Minimum 15 characters long
                  {showIcon(isLongEnough)}
                </li>
                <li>
                  Passwords Match
                  {showIcon(passwordsMatch)}
                </li>
              </ul>
              <div>Contains 3 of the following: {showIcon(sumOfTrues > 2)}</div>
              <ul>
                <li>
                  Has Upper Case
                  {showIcon(hasUppercaseLetters)}
                </li>
                <li>
                  Has Lower Case
                  {showIcon(hasLowercaseLetters)}
                </li>
                <li>
                  Has Numbers
                  {showIcon(hasNumbers)}
                </li>
                <li>
                  Has Symbols
                  {showIcon(hasSymbols)}
                </li>
              </ul>
            </div>
          </div>
          <div className="m-3">
            Your password will not be accepted if it is found on a password
            breach list
          </div>
          <form onSubmit={handleSubmit}>
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input
              name="password"
              id="password"
              type="password"
              className="form-control mb-3"
              value={password}
              disabled={passwordUpdated}
              onChange={(e) => {
                setHasTouched(true);
                setPassword(e.target.value);
              }}
            />
            <label htmlFor="confirmPassword" className="form-label">
              Confirm Password
            </label>
            <input
              name="confirmPassword"
              id="confirmPassword"
              type="password"
              className="form-control mb-3"
              disabled={passwordUpdated}
              value={confirmationPassword}
              onChange={(e) => setConfirmationPassword(e.target.value)}
            />
            <div className="row justify-content-end">
              {resetPasswordMutation.isLoading && (
                <div className="col-auto">
                  <Spinner />
                </div>
              )}
              <div className="col-auto">
                <button
                  className="btn btn-primary"
                  disabled={
                    !meetsRequirements ||
                    passwordUpdated ||
                    resetPasswordMutation.isLoading
                  }
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
          {passwordUpdated && (
            <div className="text-center mt-3 text-success fw-bold">
              Password Changed!
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
