import { useNavigate, useSearchParams } from "react-router-dom";
import { EmailValidation } from "./EmailValidation";

export const IncomingRequestWithUsername = () => {
  const [searchParams, _setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const username = searchParams.get("username");
  if (!username) {
    navigate("/");
    return <div>no email provided </div>;
  }

  return (
    <div className="container">
      <h1 className="text-center mt-3">Password Reset</h1>
      <div className="text-center">Provided Email Address: {username}</div>
      <div className="text-center fw-bold">This may take up to 15 minutes to update</div>
      <EmailValidation username={username} />
      <div className="text-center">
        <h2>Please contact the Helpdesk or IT Office with any issues:</h2>
        <h2>435-283-7777 or 435-283-7099</h2>
      </div>
    </div>
  );
};
