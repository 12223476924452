import { Link } from "react-router-dom";


export const NavBar = () => {
  return (
    <div className="bg-primary">
      <nav className="navbar navbar-expand-lg navbar-dark container">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            <img
              className="img-fluid"
              id="navbar-logo"
              alt="Snow College Logo"
              src="/SnowCollegeLogo.png"
            />
          </Link>
          
        </div>
      </nav>
    </div>
  );
};
