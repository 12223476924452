import { FormEvent, useState } from "react"
import { TextInputRow } from "../../components/forms/TextInputRow"
import { useSendUsernameLookupEmailMutation } from "../home/apiHooks"
import { useNavigate } from "react-router-dom"
import { Spinner } from "../../components/Spinner"

export const UsernameLookup = () => {
  const [firstName, setFirstName] = useState("")
  const [email, setEmail] = useState("")
  const usernameLookupEmailMutation = useSendUsernameLookupEmailMutation()
  const navigate = useNavigate();

  const nameInputControl = {
    value: firstName,
    setValue: setFirstName,
    error: "Please input a value"
  }
  const emailInputControl = {
    value: email,
    setValue: setEmail,
    error: "Please input a value"
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    usernameLookupEmailMutation.mutateAsync({ firstName: firstName, email: email })
      .then(() => {
        setFirstName("")
        setEmail("")
        navigate("/usernameEmail")
      })
  }

  if (usernameLookupEmailMutation.isLoading) return <Spinner />
  const disabled = firstName === "" || email === ""
  return (
    <div className="container text-center">
      <h1>Snow College Email Address Lookup</h1>
      <div className="row justify-content-center">
        <div className="col-auto">
          <form onSubmit={handleSubmit}>
            <div className="my-2">
              <TextInputRow label="First Name" placeholder="John" control={nameInputControl} inputClassName="col" labelClassName="col-4" />
            </div>
            <div className="text-muted small">Personal email address used during admission</div>
            <TextInputRow label="Email" control={emailInputControl} inputClassName="col" labelClassName="col-4" placeholder="youremail@gmail.com" />
            <button className="btn btn-bold my-2 w-100"
              disabled={disabled}>Submit</button>
          </form>
        </div>
      </div>
      <div className="text-center">
        <h2>Please contact the Helpdesk or IT Office with any issues:</h2>
        <h2>435-283-7777 or 435-283-7099</h2>
      </div>
    </div>
  )
}