import { useSearchParams } from "react-router-dom";
import { PasswordReset } from "./PasswordReset";

export const PasswordResetWrapper = () => {
  const [searchParams, _setSearchParams] = useSearchParams();
  const token = searchParams.get("t");

  if (!token) return <div> Invalid token </div>;

  return (
    <div className="container">
      <h1 className="text-center">Set Your New Password</h1>
      <PasswordReset token={token} />
      <br />
      <div className="text-center">
        <h2>Please contact the IT Office with any issues:</h2>
        <h2>435-283-7099</h2>
      </div>
      <hr />
      <div className="row justify-content-center">
        <div className="col-auto">
          <h4>Employee Password Requirements:</h4>
          <div className="row justify-content-center">
            <div className="col-auto text-left">
              <ul>
                <li>Must contain at least 15 characters</li>
                <li>Must not contain your username</li>
                <li>
                  Must not contain 3 or more consecutive identical characters
                </li>
                <li>
                  Must not be from the list of prohibited passwords (summer,
                  fall, spring, badgers, etc)
                </li>
                <li>Must not be in the list of breached passwords</li>
                <li>Must not repeat any of your previous 5 passwords</li>
                <li>Must contain at least 1 upper case letter</li>
                <li>Must contain at least one number or symbol</li>
              </ul>
            </div>
          </div>
          <h5>Employee Password expiration times:</h5>
          <ul>
            <li>
              Password never expires if: 20+ characters (Must contain: 1
              capital)
            </li>
          </ul>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};
