import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";


export const useResetPasswordMutation = () => useMutation(
  async ({ password, token }: { password: string, token: string }) => {
    const url = '/api/newpassword';
    const body = {
      newpassword: password,
      token
    }
    const response = await axios.post(url, body);
    return response.data;
  }
)

export const useTokenStatusQuery = (token: string) =>
  useQuery(
    ["tokenstatusquery", token],
    async (): Promise<{ expired: boolean, used: boolean }> => {
      const url = `/api/token/status?token=${token}`
      const response = await axios.get(url)
      return response.data;
    }
  )