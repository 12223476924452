import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { snakeToCamel } from "../../services/apiMapper";

const userkeys = {
  getObfuscatedEmails: (username: string) =>
    ["getobfuscatedemailskey", username] as const,
};

export const useGetObfuscatedEmailsQuery = (username: string) =>
  useQuery(
    userkeys.getObfuscatedEmails(username),
    async (): Promise<string[] | { employeeMessageSent: boolean }> => {
      const url = `/api/obfuscatedemails?username=${username}`;
      const response = await axios.get(url);
      const emailsOrMessage = snakeToCamel(response.data);
      if (emailsOrMessage.isEmployee) {
        return { employeeMessageSent: true };
      }
      return response.data;
    }
  );

export const useSendResetEmailMutation = (username: string) =>
  useMutation(async (email: string) => {
    const url = `/api/sendresetemail?email=${email}&username=${username}`;
    const response = await axios.post(url);
    return response.data;
  });

export const useSendUsernameLookupEmailMutation = () =>
  useMutation(
    async ({ firstName, email }: { firstName: string; email: string }) => {
      const url = `/api/username/lookup_email?first_name=${firstName}&email=${email}`;
      const response = await axios.post(url);
      return response.data;
    }
  );
