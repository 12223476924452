import React, { FC, FormEvent, useState } from "react";
import { Spinner } from "../../components/Spinner";
import {
  useGetObfuscatedEmailsQuery,
  useSendResetEmailMutation,
} from "./apiHooks";

export const EmailValidation: FC<{ username: string }> = ({ username }) => {
  const hiddenEmailsQuery = useGetObfuscatedEmailsQuery(username);
  const sendResetEmailMutation = useSendResetEmailMutation(username);
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  if (hiddenEmailsQuery.isLoading) return <Spinner />;
  if (
    hiddenEmailsQuery.isError &&
    !hiddenEmailsQuery.data &&
    !Array.isArray(hiddenEmailsQuery.data)
  )
    return <div className="m-3 text-center">email address error</div>;
  if (hiddenEmailsQuery.isError)
    return <div className="m-3 text-center">Email address does not exist</div>;
  if (!hiddenEmailsQuery.data)
    return <div className="m-3 text-center">no data for email address</div>;

  if (!Array.isArray(hiddenEmailsQuery.data)) {
    return (
      <div className="m-3 text-success text-center">
        A reset link was sent to your employee email
      </div>
    );
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    sendResetEmailMutation.mutateAsync(email).then(() => {
      setEmailSent(true);
    });
  };
  return (
    <>
      <hr />
      <div className="row justify-content-center">
        <div className="col-auto col-md-6">
          <div className="form-group">
            <h3>Identity Verification</h3>
            <div>
              Please enter the personal email address associated with your Snow
              College account for identity verification. This should match one
              of the obscured email hints below.
            </div>
            <ul>
              {hiddenEmailsQuery.data.map((e) => (
                <li>{e}</li>
              ))}
            </ul>
            <form onSubmit={handleSubmit}>
              <label htmlFor="emailinput" className="form-label">
                Once verified, we will send a password reset link to the
                personal email address you provide.
              </label>
              <input
                type="text"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={sendResetEmailMutation.isLoading || emailSent}
              />
              {sendResetEmailMutation.isLoading ? (
                <Spinner />
              ) : (
                <div className="mt-3">
                  <button
                    className="btn btn-primary w-100"
                    disabled={emailSent}
                  >
                    Submit
                  </button>
                  {emailSent && (
                    <div className="my-auto text-success">
                      A verification message has been sent to your personal
                      email address. Please follow the instructions in the email
                      to reset your Snow College account password. If you do not
                      receive the email shortly, please contact the IT Office at
                      435-283-7099 for support.
                    </div>
                  )}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
